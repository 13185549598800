<template>
  <div class="tw-w-full md:tw-w-4/5 lg:tw-w-2/3 tw-mx-auto tw-mb-8">
    <DownloadAppButtons />
    <p class="tw-text-xl tw-mb-4">
      View 10-day weather forecasts for your current and favorite locations, read expert analysis from local forecasters, track storms with high-resolution 3D weather maps, receive custom alerts, and more.
    </p>
    <p class="tw-text-lg tw-mb-8">
      "Weather forecasting for the mountains takes extra focus, analysis, and precision, which
      is exactly what OpenSnow provides." -
      <a
        class="tw-underline"
        href="https://www.instagram.com/p/C4LqK-4Pdth/"
      >Cody Townsend</a>,
      Pro Skier
    </p>
    <section
      v-for="section in sections"
      :key="section.title"
      class="tw-mb-8"
    >
      <h2 class="tw-mb-6 tw-text-center section-title heading-color">
        {{ section.title }}
      </h2>
      <img
        class="tw-w-9/12 md:tw-w-1/2 lg:tw-w-5/12 tw-mx-auto"
        loading="lazy"
        :src="section.image"
      >
      <p class="tw-text-lg tw-mt-4">
        {{ section.text }}
      </p>
    </section>
    <DownloadAppButtons />
  </div>
</template>

<script>
export default {
  name: 'OpenSnowApp',

  computed: {
    appStoreUrl() {
      return 'https://apps.apple.com/us/app/opensnow-ski-forecasts-reports/id780890907?ls=1';
    },

    playStoreUrl() {
      return 'https://play.google.com/store/apps/details?id=com.opensnow.android';
    },

    sections() {
      return [
        {
          image: 'https://blizzard.opensnow.com/app-images/500x1004/my-location-16-2.png',
          title: 'Your Daily Weather App',
          text: 'It\'s time to delete those "other" weather apps. Get everything you need with OpenSnow, including current conditions, 10-day forecast, astronomy data, and historical weather for your current location.',
        },
        {
          image: 'https://blizzard.opensnow.com/app-images/500x1004/palisades-snow-summary-plumes-16.png',
          title: 'Compare 10-Day Forecasts',
          text: 'Finding the location that has the best conditions can feel overwhelming. With OpenSnow, deciding where to go is easy. View the latest 10-day weather forecast, snow report, and mountain cams for your favorite locations in just a few seconds.',
        },
        {
          image: 'https://blizzard.opensnow.com/app-images/500x1004/dailysnow-jacksonhole-16.png',
          title: 'Read Expert Analysis',
          text: 'Instead of spending hours sifting through weather data, get the inside scoop in just a few minutes. Our local experts write a new "Daily Snow" forecast each day for regions around the US, Canada, Europe, Scandinavia, Australia, and New Zealand. Have one of our expert local forecasters guide you to the best conditions.',
        },
        {
          image: 'https://blizzard.opensnow.com/app-images/500x1004/3d-maps-16.png',
          title: 'Explore 3D Maps',
          text: 'We make it easy to track incoming storms with current and forecast radar over high-resolution 3D terrain and satellite maps. You can also view forecast animations for air quality and wildfire smoke, along with the current lightning risk, hail size, snow depth, avalanche forecast, active fire perimeters, public and private land ownership maps, and more.',
        },
        {
          image: 'https://blizzard.opensnow.com/app-images/500x1004/forecast-anywhere-map-norway-16-2.png',
          title: 'Get Forecast Anywhere',
          text: 'Our weather forecasts are available for any location on Earth. This means that you can view our weather forecasts for your favorite ski resort, backcountry ski location, or camping destination. Save up to 15 custom locations to view on your Favorites screen for convenient access to the latest conditions and 10-day forecast.',
        },
        {
          image: 'https://blizzard.opensnow.com/app-images/500x1004/highlands-notification-16-2.png',
          title: 'Receive Custom Snow Alerts',
          text: 'Stay up-to-date and never miss another snow day with custom snow report and forecast alerts for your favorite ski resorts.',
        },
        {
          image: 'https://blizzard.opensnow.com/app-images/500x1004/ios-widgets-16.png',
          title: 'Add iOS Widgets',
          text: 'See current conditions, check the latest 10-day forecast, and keep an eye on webcams for your favorite locations, directly on your Android, iPhone, or Mac home screen.',
        },
      ];
    },
  },
};
</script>
